import React, { Component } from 'reactn'
import { Card, TableDetail } from './Components'
import { Api, persistState } from '../Services'
import Navbar from '../Components/Navbar'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import filterFactory from 'react-bootstrap-table2-filter'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import swal from 'sweetalert'

import up from '../Assets/Icons/up.png'
import down from '../Assets/Icons/down.png'

const renderCell = (cell, type) => <p style={styles.cell}>{cell}</p>
const renderDate = (cell) => renderCell(new Date(cell).toLocaleString('it-IT').split(',')[0])
// const timeFormatter = (cell) => renderCell(new Date(cell).toLocaleString('it-IT').split(',')[1])

export default class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lavori: []
    }
  }

  async componentDidMount () {
    await this.refresh()
  }

  async refresh () {
    const { user } = this.global
    const ret = await Api.get('/lavori', { companyId: user.company })
    if (ret.ok) {
      this.setState({ lavori: ret.data })
    } else {
      swal('Errore', 'Impossibile inizializzare la pagina. Contattare l\'assistenza', 'error')
    }
  }

  async open (row) {
    await this.setGlobal({ row }, persistState)
    this.props.history.push('/')
  }

  async delete (row) {
    const docId = row._id
    const ret = await Api.delete(`/lavori/${docId}`)
    if (ret.ok) {
      swal('Successo', 'Il documento é stato eliminato correttamente', 'success', { timer: 2000, buttons: false }).then(() => this.refresh())
    } else {
      swal('Errore', 'Errore durante l\'eliminazione dei dati. Si prega di riprovare', 'error')
    }
  }

  render () {
    const { lavori } = this.state
    const { container, table, toolkit } = styles
    const { SearchBar, ClearSearchButton } = Search

    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }]

    const expandRow = {
      renderer: row => <TableDetail row={row} open={row => this.open(row)} delete={row => this.delete(row)} />,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <img src={up} width={20} height={20} alt='' />
            </div>
          )
        }
        return (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={down} width={20} height={20} alt='' />
          </div>
        )
      }
    }

    const rowEvents = {
      onClick: async (e, row, rowIndex) => {}
    }

    const rowBackground = (row, rowIndex) => {
      const style = {}
      rowIndex % 2 === 0 ? style.backgroundColor = '#cfdede' : style.backgroundColor = '#f9f9f9'
      return style
    }

    const options = {
      paginationSize: 4,
      pageStartIndex: 0,
      alwaysShowAllBtns: true,
      withFirstAndLast: true,
      firstPageText: 'Inzio',
      prePageText: 'Indietro',
      nextPageText: 'Avanti',
      lastPageText: 'Fine',
      showTotal: true,
      paginationTotalRenderer: (from, to, size) => {
        return (<i style={{ marginLeft: 10 }}>Records da <b>{from}</b> a <b>{to}</b> di <b>{size}</b> risultati</i>)
      },
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }]
    }

    return (
      <>
        <Navbar title='Storico Lavori' />
        <Card style={container}>
          <Card shadow style={table}>
            <ToolkitProvider
              keyField='_id'
              data={lavori}
              columns={fields}
              style={{ width: '100%' }}
              rowEvents={rowEvents}
              loading
              striped
              hover
              search
              exportCSV
            >
              {
                props =>
                  <Card style={{ width: '100%', padding: 20 }}>
                    <Card style={toolkit}>
                      {/* <ExportCSVButton {...props.csvProps} style={exportBtn} >CSV</ExportCSVButton> */}
                      <SearchBar
                        {...props.searchProps}
                        placeholder='Inizia a digitare per cercare nella tabella...'
                        delay={50}
                        srText=''
                        style={{ width: 800, height: 40 }}
                      />
                      <ClearSearchButton {...props.searchProps} text='Reset' className='reset-btn' />
                    </Card>
                    <hr />
                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication='Non ci sono dati da visualizzare.'
                      filter={filterFactory()}
                      pagination={paginationFactory(options)}
                      expandRow={expandRow}
                      tabIndexCell
                      bootstrap4
                      defaultSorted={defaultSorted}
                      rowEvents={rowEvents}
                      rowStyle={rowBackground}
                      wrapperClasses='table-responsive'
                    />
                  </Card>
              }
            </ToolkitProvider>
          </Card>
        </Card>
      </>
    )
  }
}

const styles = {
  column: {
    backgroundColor: '#00a660',
    color: 'white',
    fontSize: 12,
    textTransform: 'uppercase'
  },
  row: {
    color: 'white',
    height: 20
  },
  cell: {
    fontSize: 15,
    wordWrap: 'break-word'
  },
  container: {
    display: 'flex',
    backgroundColor: 'rgba(230,230,230,0.8)',
    margin: 20
  },
  table: {
    width: '100%'
  },
  toolkit: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  exportBtn: {
    width: 100,
    height: 40,
    backgroundColor: 'red',
    color: 'white',
    marginRight: 10,
    marginTop: 5
  }
}

const fields = [
  {
    dataField: '_id',
    text: 'ID',
    headerStyle: { ...styles.column, width: '15%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left',
    events: {
    }
  },
  {
    dataField: 'committente',
    text: 'Committente',
    headerStyle: { ...styles.column, width: '20%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left'
  },
  {
    dataField: 'indirizzo',
    text: 'Indirizzo',
    headerStyle: { ...styles.column, width: '40%' },
    sort: true,
    formatter: cell => renderCell(cell),
    align: 'left'
  },
  {
    dataField: 'dataEsecuzione',
    text: 'Data Esecuzione',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: cell => renderDate(cell),
    align: 'left'
  },
  {
    dataField: 'tipologiaIntervento',
    text: 'Tipo Intervento',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: cell => renderCell(cell, 'office'),
    align: 'left'
  },
  {
    dataField: 'createdAt',
    text: 'Data Documento',
    headerStyle: { ...styles.column, width: '10%' },
    sort: true,
    formatter: cell => renderDate(cell),
    align: 'left'
  }
]
