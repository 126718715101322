import React, { Component } from 'reactn'
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap'
import swal from 'sweetalert'
import * as FaIcons from 'react-icons/fa'
import { Api } from '../Services'
import Navbar from '../Components/Navbar'
import Config from '../Global/Config'

export default class Company extends Component {
  constructor (props) {
    super(props)
    this.state = {
      companyName: '',
      companyAddress: '',
      vat: '',
      personInCharge: [],
      firstAid: [],
      firePrevention: [],
      operatori: [],

      userName: '',
      userEmail: '',
      userPhone: '',
      companyAffiliation: '',
      newPassword: '',
      confirmPassword: ''
    }
  }

  componentDidMount () {
    this.startup()
  }

  async startup () {
    const { user } = this.global

    const companyId = user.company
    const res = await Api.get(`/companies/${companyId}`)

    if (res.ok) {
      const company = res.data
      const {
        _id, name, address, vat,
        personInCharge, firstAid,
        firePrevention, operatori
      } = company

      this.setState({
        companyId: _id.toString(),
        companyName: name || '',
        companyAddress: address || '',
        vat: vat || '',
        personInCharge: personInCharge || [],
        firstAid: firstAid || [],
        firePrevention: firePrevention || [],
        operatori: operatori || [],

        userId: user._id.toString(),
        userName: user.name,
        userEmail: user.email,
        userPhone: user.phone,
        companyAffiliation: name
      })
    } else {
      swal('Errore', 'Impossibile inizializzare la pagina. Contattare l\'assistenza', 'error')
    }
  }

  async saveCompanyData () {
    const { companyId, companyName, companyAddress, vat, personInCharge, firstAid, firePrevention, operatori } = this.state
    const data = { name: companyName, address: companyAddress, vat, personInCharge, firstAid, firePrevention, operatori }
    const ret = await Api.patch(`/companies/${companyId}`, data)
    if (ret.ok) {
      swal('Successo', 'I dati aziendali sono stati aggiornati correttamente.', 'success')
    } else {
      swal('Errore', 'Impossibile aggiornare i dati aziendali. Contattare l\'assistenza.', 'error')
    }
  }

  async saveUserData () {
    const { userId, userName, userPhone, newPassword, confirmPassword } = this.state

    let data = { name: userName, phone: userPhone }

    if (newPassword === confirmPassword && newPassword !== '' && confirmPassword !== '') {
      data = { ...data, password: newPassword }
    } else if (newPassword !== confirmPassword && newPassword !== '' && confirmPassword !== '') {
      swal('Errore', 'Le due password inserite non corrispondono.', 'error')
    }

    const ret = await Api.patch(`/users/${userId}`, data)
    if (ret.ok) {
      swal('Successo', 'I dati utente sono stati aggiornati correttamente.', 'success')
    } else {
      swal('Errore', 'Impossibile aggiornare i dati utente. Contattare l\'assistenza.', 'error')
    }
  }

  addPersonInCharge () {
    const { personInCharge, vPersonInCharge } = this.state
    const exists = personInCharge.filter(p => p === vPersonInCharge)
    exists.length === 0 && this.setState({ personInCharge: personInCharge.concat(vPersonInCharge), vPersonInCharge: '' })
  }

  removePersonInCharge (item) {
    const personInCharge = this.state.personInCharge.filter(p => p !== item)
    this.setState({ personInCharge })
  }

  addFirstAid () {
    const { firstAid, vFirstAid } = this.state
    const exists = firstAid.filter(p => p === vFirstAid)
    exists.length === 0 && this.setState({ firstAid: firstAid.concat(vFirstAid), vFirstAid: '' })
  }

  removeFirstAid (item) {
    const firstAid = this.state.firstAid.filter(p => p !== item)
    this.setState({ firstAid })
  }

  addFirePrevention () {
    const { firePrevention, vFirePrevention } = this.state
    const exists = firePrevention.filter(p => p === vFirePrevention)
    exists.length === 0 && this.setState({ firePrevention: firePrevention.concat(vFirePrevention), vFirePrevention: '' })
  }

  removeFirePrevention (item) {
    const firePrevention = this.state.firePrevention.filter(p => p !== item)
    this.setState({ firePrevention })
  }

  addOperatore () {
    const { operatori, vOperatore } = this.state
    const exists = operatori.filter(p => p === vOperatore)
    exists.length === 0 && this.setState({ operatori: operatori.concat(vOperatore), vOperatore: '' })
  }

  removeOperatore (item) {
    const operatori = this.state.operatori.filter(p => p !== item)
    this.setState({ operatori })
  }

  render () {
    const {
      companyName, companyAddress, vat, personInCharge, firstAid, firePrevention, operatori,
      userName, userEmail, userPhone, companyAffiliation, newPassword, confirmPassword,
      vOperatore, vFirePrevention, vFirstAid, vPersonInCharge
    } = this.state
    const { baseCard, title, titleBox, list } = styles

    return (
      <>
        <Navbar title='Azienda' />
        <div className='new'>

          <div style={{ maxWidth: '100%', width: '38%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>ANAGRAFICA AZIENDA</b>
                <hr />
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Ragione Sociale</InputGroup.Text>
                  <FormControl value={companyName} onChange={e => this.setState({ companyName: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Indirizzo</InputGroup.Text>
                  <FormControl value={companyAddress} onChange={e => this.setState({ companyAddress: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Partita IVA</InputGroup.Text>
                  <FormControl value={vat} onChange={e => this.setState({ vat: e.target.value })} />
                </InputGroup>

                <InputGroup className='mb-2' style={{ marginTop: 30 }}>
                  <InputGroup.Text style={titleBox}>Nuovo Preposto</InputGroup.Text>
                  <FormControl
                    value={vPersonInCharge}
                    placeholder='Digitare e premere invio'
                    onChange={e => this.setState({ vPersonInCharge: e.target.value })}
                    onKeyPress={e => e.key === 'Enter' && this.addPersonInCharge()}
                  />
                </InputGroup>
                {
                  personInCharge && personInCharge.length > 0 && personInCharge.map((item, i) => {
                    return (
                      <div key={i} style={list}>
                        <span>• {item}</span>
                        <FaIcons.FaTrash
                          size={18}
                          color='red'
                          style={{ marginRight: 10 }}
                          onClick={() => this.removePersonInCharge(item)}
                        />
                      </div>
                    )
                  })
                }

                <InputGroup className='mb-2' style={{ marginTop: 30 }}>
                  <InputGroup.Text style={titleBox}>Nuovo Addetto primo soccorso</InputGroup.Text>
                  <FormControl
                    value={vFirstAid}
                    placeholder='Digitare e premere invio'
                    onChange={e => this.setState({ vFirstAid: e.target.value })}
                    onKeyPress={e => e.key === 'Enter' && this.addFirstAid()}
                  />
                </InputGroup>
                {
                  firstAid && firstAid.length > 0 && firstAid.map((item, i) => {
                    return (
                      <div key={i} style={list}>
                        <span>• {item}</span>
                        <FaIcons.FaTrash
                          size={18}
                          color='red'
                          style={{ marginRight: 10 }}
                          onClick={() => this.removeFirstAid(item)}
                        />
                      </div>
                    )
                  })
                }

                <InputGroup className='mb-2' style={{ marginTop: 30 }}>
                  <InputGroup.Text style={titleBox}>Nuovo Addetto antincendio</InputGroup.Text>
                  <FormControl
                    value={vFirePrevention}
                    placeholder='Digitare e premere invio'
                    onChange={e => this.setState({ vFirePrevention: e.target.value })}
                    onKeyPress={e => e.key === 'Enter' && this.addFirePrevention()}
                  />
                </InputGroup>
                {
                  firePrevention && firePrevention.length > 0 && firePrevention.map((item, i) => {
                    return (
                      <div key={i} style={list}>
                        <span>• {item}</span>
                        <FaIcons.FaTrash
                          size={18}
                          color='red'
                          style={{ marginRight: 10 }}
                          onClick={() => this.removeFirePrevention(item)}
                        />
                      </div>
                    )
                  })
                }

                <InputGroup className='mb-2' style={{ marginTop: 30 }}>
                  <InputGroup.Text style={titleBox}>Operatori</InputGroup.Text>
                  <FormControl
                    value={vOperatore}
                    placeholder='Digitare e premere invio'
                    onChange={e => this.setState({ vOperatore: e.target.value })}
                    onKeyPress={e => e.key === 'Enter' && this.addOperatore()}
                  />
                </InputGroup>
                {
                  operatori && operatori.length > 0 && operatori.map((item, i) => {
                    return (
                      <div key={i} style={list}>
                        <span>• {item}</span>
                        <FaIcons.FaTrash
                          size={18}
                          color='red'
                          style={{ marginRight: 10 }}
                          onClick={() => this.removeOperatore(item)}
                        />
                      </div>
                    )
                  })
                }

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    size='md'
                    variant='success'
                    onClick={() => this.saveCompanyData()}
                  >
                    Salva
                    <FaIcons.FaSave style={{ marginLeft: 10, marginBottom: 5 }} size={25} />
                  </Button>
                </div>
              </Card.Body>
            </Card>

            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>PROFILO UTENTE</b>
                <hr />
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Nome</InputGroup.Text>
                  <FormControl value={userName} onChange={e => this.setState({ userName: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Email</InputGroup.Text>
                  <FormControl value={userEmail} disabled />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Telefono</InputGroup.Text>
                  <FormControl value={userPhone} onChange={e => this.setState({ userPhone: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Azienda</InputGroup.Text>
                  <FormControl value={companyAffiliation} disabled />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Nuova Password</InputGroup.Text>
                  <FormControl type='password' value={newPassword} onChange={e => this.setState({ newPassword: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Conferma Password</InputGroup.Text>
                  <FormControl type='password' value={confirmPassword} onChange={e => this.setState({ confirmPassword: e.target.value })} />
                </InputGroup>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    size='md'
                    variant='success'
                    onClick={() => this.saveUserData()}
                  >
                    Salva
                    <FaIcons.FaSave style={{ marginLeft: 10, marginBottom: 5 }} size={25} />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    )
  }
}

const styles = {
  baseCard: {
    width: '100%',
    minWidth: 360,
    margin: 10,
    ...Config.shadow,
    borderRadius: 5
  },
  title: {
    fontSize: 14,
    color: Config.textColor
  },
  titleBox: {
    fontSize: 12,
    color: Config.textColor,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  list: {
    fontSize: 16,
    marginLeft: 20,
    marginRight: 10,
    // fontWeight: 'bold',
    backgroundColor: 'rgba(240,240,240,0.6)',
    borderRadius: 20,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  }
}
