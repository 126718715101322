import React from 'react'
import * as FaIcons from 'react-icons/fa'

export const SidebarData = [
  {
    title: 'Nuovo Documento',
    path: '/',
    icon: <FaIcons.FaFolderPlus />,
    cName: 'nav-text'
  },
  {
    title: 'Storico Lavori',
    path: '/works',
    icon: <FaIcons.FaFile />,
    cName: 'nav-text'
  },
  {
    title: 'Azienda',
    path: '/company',
    icon: <FaIcons.FaUser />,
    cName: 'nav-text'
  }
]
