import React, { Component } from 'reactn'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { IconContext } from 'react-icons'
import { SidebarData } from './SidebarData'
import './Navbar.css'
import Config from '../Global/Config'
import { clearState, persistState } from '../Services'

export default class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sidebar: false
    }
  }

  async logout () {
    await this.setGlobal({ loggedIn: false }, persistState)
    clearState()
    window.location.reload()
  }

  render () {
    const { sidebar } = this.state
    const { navbar, title, subtitle } = styles
    const showSidebar = () => this.setState({ sidebar: !sidebar })

    return (
      <div style={{ ...Config.shadow }}>
        <IconContext.Provider value={{ color: '#fff' }}>
          <div style={{ ...navbar, fontSize: this.props.subtitle ? 22 : 25 }}>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={title}>{this.props.title}</span>
              <span style={subtitle}>{this.props.subtitle}</span>
            </div>
            <Link to='/' className='menu-logout'>
              <FaIcons.FaPowerOff onClick={() => this.logout()} />
            </Link>
          </div>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-item' onClick={showSidebar}>
              <li className='navbar-toggle'>
                <Link to='#' className='menu-bars' style={{ marginLeft: -10, marginBottom: 20 }}>
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              <li className='nav-text'>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: -20, marginBottom: 20 }}>
                  <span style={{ ...title, fontSize: 22 }}>Benvenuto,</span>
                  <span style={{ ...subtitle, fontSize: 18 }}>{this.global.user && this.global.user.name}</span>
                </div>
              </li>
              <hr style={{ backgroundColor: 'white' }} />
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    )
  }
}

const styles = {
  navbar: {
    backgroundColor: '#00a660',
    minHeight: 60,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  title: {
    marginLeft: 30,
    color: 'white',
    marginTop: 5
  },
  subtitle: {
    marginLeft: 30,
    fontSize: 12,
    color: 'white',
    marginTop: 5,
    marginBottom: 5
  }
}
