import React, { Component, addCallback } from 'reactn'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Works, Company, New, Login } from './Pages'
import Background from './Components/Background'
import { restoreState } from './Services'
import './App.css'

export default class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loggedIn: false
    }
  }

  async componentDidMount () {
    await restoreState()
    this.setState({ loggedIn: this.global.loggedIn })

    addCallback(global => {
      console.log('callback')
      if (global.loggedIn === true || global.loggedIn === false) {
        this.setState({ loggedIn: global.loggedIn })
      }

      return null
    })
  }

  renderApp () {
    return (
      <Router>
        <Switch>
          <Route path='/' exact component={New} />
          <Route path='/works' component={Works} />
          <Route path='/company' component={Company} />
        </Switch>
      </Router>
    )
  }

  postLogin () {
    this.setState({ loggedIn: true })
  }

  render () {
    const { loggedIn } = this.state
    return (
      <Background>
        {loggedIn
          ? this.renderApp()
          : <Login postLogin={() => this.postLogin()} />}
      </Background>
    )
  }
}
