const Config = {
  API_BASE_URL: 'https://api.formazione3t.it',
  // API_BASE_URL: 'http://localhost:3030',
  STORAGE_KEY: 'FORMAZIONE3T',
  shadow: {
    WebkitBoxShadow: '0px 0px 15px 0px rgba(163,163,163,1)',
    MozBoxShadow: '0px 0px 15px 0px rgba(163,163,163,1)',
    boxShadow: '0px 0px 15px 0px rgba(163,163,163,1)'
  },
  textColor: '#4b4b4c',
  logo: require('../Assets/logo.png'),
  appVersion: '1.0.1'
}

export default Config
