import { Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer' // Image
import moment from 'moment'
import Config from '../Global/Config'
import logo from '../Assets/logo.base64.json'

const MyDocument = ({ data }) => {
  const {
    body, header, page, title, hor,
    subtitle, textValue, textValueTitle, smallTextValue
  } = styles

  const {
    companyName, companyAddress, vat, preposto, primoSoccorso, prevenzioneIncendi,
    dataSopralluogo, committente, indirizzo, indicazioni, dataEsecuzione, tipologiaIntervento,
    numero, specie, dimensioni, comunicazione, rischioCaduta, rischioTaglio,
    rischioAmbiente, interferenze, pedoni, condotte, rischioUrti, rischioClima,
    rischioBiologico, rischioChimico, rischioSchegge, rischioSchiacciamento,
    rischioAltri, noteAltriRischi, ple, tc, treeClimbing, protezioneCadute, protezioneSpecifici,
    segacci, motoseghe, abbattimento, cippatrice, fresaceppi, attrezzatureAltre, noteAltreAttrezzature,
    note, operatori
  } = data

  return (
    <Document>
      <Page size='A4' style={page}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Image src={logo.base64} style={{ width: 50, height: 50, marginLeft: 30 }} />
          <Text style={header}>Programma dei lavori (D.Lgs. 81/08, art. 116, comma e) ed f))</Text>
        </View>

        <Text style={title}>1. ANAGRAFICA DITTA</Text>
        <View style={body}>
          <View style={hor}><Text style={subtitle}>Ragione Sociale:</Text><Text style={textValue}>{companyName}</Text></View>
          <View style={hor}><Text style={subtitle}>Indirizzo:</Text><Text style={textValue}>{companyAddress}</Text></View>
          <View style={hor}><Text style={subtitle}>Partita IVA:</Text><Text style={textValue}>{vat}</Text></View>
          <View style={hor}><Text style={subtitle}>Preposto:</Text><Text style={textValue}>{preposto}</Text></View>
          <View style={hor}><Text style={subtitle}>Addetto primo soccorso:</Text><Text style={textValue}>{primoSoccorso}</Text></View>
          <View style={hor}><Text style={subtitle}>Addetto antincendio:</Text><Text style={textValue}>{prevenzioneIncendi}</Text></View>
        </View>

        <Text style={title}>2. DATI LUOGO DI LAVORO</Text>
        <View style={body}>
          <View style={hor}><Text style={subtitle}>Data sopralluogo:</Text><Text style={textValue}>{moment(dataSopralluogo).format('DD/MM/YYYY')}</Text></View>
          <View style={hor}><Text style={subtitle}>Committente:</Text><Text style={textValue}>{committente}</Text></View>
          <View style={hor}><Text style={subtitle}>Indirizzo:</Text><Text style={textValue}>{indirizzo}</Text></View>
          <View style={hor}><Text style={subtitle}>Indicazioni per l'accesso:</Text><Text style={textValue}>{indicazioni}</Text></View>
          <View style={hor}><Text style={subtitle}>Data esecuzione:</Text><Text style={textValue}>{moment(dataEsecuzione).format('DD/MM/YYYY')}</Text></View>
        </View>

        <Text style={title}>3. DATI INTERVENTI DA ESEGUIRE</Text>
        <View style={body}>
          <View style={hor}><Text style={subtitle}>Tipologia intervento:</Text><Text style={textValue}>{tipologiaIntervento}</Text></View>
          <View style={hor}><Text style={subtitle}>Numero:</Text><Text style={textValue}>{numero}</Text></View>
          <View style={hor}><Text style={subtitle}>Specie:</Text><Text style={textValue}>{specie}</Text></View>
          <View style={hor}><Text style={subtitle}>Altezza, diametro:</Text><Text style={textValue}>{dimensioni}</Text></View>
        </View>

        <Text style={title}>4. SQUADRA DI LAVORO</Text>
        <View style={body}>
          <View style={{ marginLeft: 30 }}>
            {operatori && operatori.length > 0 && operatori.map((user, index) => {
              return (
                <View style={hor} key={index}>
                  <Text style={subtitle}>Operatore {index + 1}:</Text>
                  <View style={hor}>
                    <Text style={textValue}>{user.nome}, </Text>
                    <Text style={textValue}>{user.luogo}, </Text>
                    <Text style={textValue}>{user.tipo}</Text>
                  </View>
                </View>
              )
            })}
          </View>
        </View>

        <Text style={title}>5. VALUTAZIONE DEI RISCHI</Text>
        <View style={body}>
          {comunicazione && <Text style={textValueTitle}>• Comunicazione</Text>}

          {rischioCaduta && (
            <View>
              <Text style={textValueTitle}>• Rischio caduta dall'alto</Text>
              <Text style={smallTextValue}>
                Nello svolgimento delle operazioni in quota, il rischio caduta dall’alto verrà prevenuto utilizzando sistemi ed attrezzature adeguate
                ed appropriate al contesto ed alla tipologia di lavorazioni da eseguire. Nella scelta di tali sistemi verrà data la priorità a Dispositivi
                di Protezione Collettiva. Nel caso l’utilizzo di DPC, quali piattaforma di lavoro elevabile, non fosse praticabile, verranno utilizzati
                sistemi di lavoro in quota su fune, adottando tecniche di tree climbing
              </Text>
            </View>
          )}

          {rischioTaglio && (
            <View>
              <Text style={textValueTitle}>• Rischio di taglio</Text>
              <Text style={smallTextValue}>
                Gli operatori sono formati, informati e addestrati all’utilizzo delle attrezzature da taglio (motoseghe), e verranno utilizzati tutti i DPI previsti.
              </Text>
            </View>
          )}

          {rischioAmbiente && (
            <View>
              <Text style={textValueTitle}>• Rischi derivanti dall'ambiente circostante</Text>
              <Text style={smallTextValue}>
                ________________________________________________________________________________________{'\n\n\n'}
                ________________________________________________________________________________________
              </Text>
            </View>
          )}

          {interferenze && (
            <View>
              <Text style={textValueTitle}>• Presenza e interferenza con traffico veicolare</Text>
              <Text style={smallTextValue}>
                Saranno presenti operatori formati, informati e addestrati alla gestione delle attività in presenza di traffico veicolare.
                Ove necessario saranno adottati sistemi di segnalazione temporanea e modifiche alla circolazione come previsto dalla normativa
                ed in accordo con l’Ente di competenza.
              </Text>
            </View>
          )}

          {pedoni && (
            <View>
              <Text style={textValueTitle}>• Possibile passaggio di pedoni</Text>
              <Text style={smallTextValue}>
                L’area di lavoro verrà delimitata, segnalata e sorvegliata per tutta la durata delle operazioni.
                Se l’area di lavoro è all’interno di una proprietà privata recintata, le uniche persone che avranno necessità
                di accedere all’area sono i committenti, e dovranno tempestivamente segnalare la loro presenza al personale a terra.
              </Text>
            </View>
          )}

          {condotte && (
            <View>
              <Text style={textValueTitle}>• Presenza di condotte di energia elettrica</Text>
              <Text style={smallTextValue}>
                Nel caso non sia possibile operare a distanza di sicurezza, le operazioni verranno eseguite a seguito dell’interruzione
                della linea, in accordo con l’Ente competente.
              </Text>
            </View>
          )}

          {rischioUrti && (
            <View>
              <Text style={textValueTitle}>• Rischio di urto con parti dell'albero o attrezzature in caduta</Text>
              <Text style={smallTextValue}>
                L'area di possibile caduta di rami o eventuali oggetti sarà interdetta ad altri operatori e a terzi; l'eventuale movimentazione dei
                rami a terra, o comunque l'eventuale necessità di accedere all'interno dell’area a rischio, avverrà solo con il via libera degli operatori
                in quota, al fine di evitare il rischio di urti accidentali.
                Gli attrezzi da taglio a motore sono collegati all’operatore mediante cordini di collegamento per evitarne la caduta.
              </Text>
            </View>
          )}

          {rischioClima && (
            <View>
              <Text style={textValueTitle}>• Rischi derivanti da situazioni climatiche</Text>
              <Text style={smallTextValue}>
                Nel caso elementi climatici quali temperatura, pioggia, neve ecc. rendessero pericoloso il proseguimento delle operazioni,
                queste verranno interrotte e riprese solo in condizioni idonee.
              </Text>
            </View>
          )}

          {rischioBiologico && (
            <View>
              <Text style={textValueTitle}>• Rischio biologico</Text>
              <Text style={smallTextValue}>
                In presenza di insetti pericolosi o dannosi vengono interrotte le operazioni formative.
              </Text>
            </View>
          )}

          {rischioChimico && (
            <View>
              <Text style={textValueTitle}>• Rischio chimico</Text>
              <Text style={smallTextValue}>
                Nell’attività non sono presenti agenti e preparati chimici pericolosi, secondo quanto previsto dalla vigente normativa.
                I quantitativi dei prodotti utilizzati possono essere considerati non significativi.
                Le modalità di utilizzo degli agenti chimici non prevedono condizioni che possano incrementare il livello di pericolo
                (quale ad esempio l’utilizzo ad alte temperature o sotto pressione, la possibilità di effetti sinergici con altre sostanze utilizzate, ecc.)
                e la frequenza e durata dell’esposizione a rischi per la salute può essere valutata non significativa.
                Il rischio di incendio è controllato attraverso specifiche misure di prevenzione e protezione.
              </Text>
            </View>
          )}

          {rischioSchegge && (
            <View>
              <Text style={textValueTitle}>• Rischio di proiezione di schegge</Text>
              <Text style={smallTextValue}>
                Verranno protette le parti del corpo esposte a tale rischio (occhi) mediante idonei DPI
              </Text>
            </View>
          )}

          {rischioSchiacciamento && (
            <View>
              <Text style={textValueTitle}>• Rischio di schiacciamento</Text>
              <Text style={smallTextValue}>
                Le operazioni di movimentazione di porzioni di albero vengono eseguite utilizzando i DPI necessari per proteggersi da possibili schiacciamenti.
              </Text>
            </View>
          )}

          {rischioAltri && <Text style={textValueTitle}>• Altri rischi</Text>}
          {rischioAltri && <Text style={textValueTitle}>• Note altri rischi</Text>}
          {rischioAltri && <Text style={{ ...textValue, marginLeft: 20, fontStyle: 'italic' }}>{noteAltriRischi}</Text>}
        </View>

        <Text style={title}>6. TIPOLOGIA OPERATIVA (PLE, TC)</Text>
        <View style={body}>
          {ple && (
            <View>
              <Text style={textValueTitle}>• PLE</Text>
              <Text style={smallTextValue}>
                Considerati la via di accesso adeguata, l’area di lavoro, la conformazione del suolo e gli interventi da eseguire vengono
                utilizzate attrezzature per il sollevamento di persone (PLE)
              </Text>
            </View>
          )}

          {tc && (
            <View>
              <Text style={textValueTitle}>• TC</Text>
              <Text style={smallTextValue}>
                L’utilizzo di PLE non permette o limita un’esecuzione sicura ed efficace dei lavori in programma.
                A seguito del sopraluogo preliminare viene considerato sicuro ed efficace utilizzare la tecnica lavorativa del tree climbing.
              </Text>
            </View>
          )}
        </View>

        <Text style={title}>7. TECNICHE E PROCEDURE OPERATIVE</Text>
        <View style={body}>
          {ple &&
            <View>
              <Text style={textValueTitle}>• Valutazione dell'area di svolgimento degli interventi</Text>
              <Text style={smallTextValue}>
                Esaminare l’area di svolgimento dei lavori per individuare l’eventuale vicinanza a strade, linee elettriche, edifici o altri manufatti e altre piante.
                Individuare la zona di pericolo, accessibile solo a personale autorizzato e in comunicazione con gli operatori in quota.
                Delimitare l’area in modo adeguato, con cartelli, nastro segnaletico e altri eventuali elementi.
              </Text>

              <Text style={textValueTitle}>• Operazioni di lavoro a mezzo PLE</Text>
              <Text style={smallTextValue}>
                Gli operatori che utilizzeranno la Piattaforma di Lavoro elevabile hanno ricevuto formazione, informazione ed addestramento a tale scopo,
                ai sensi del D.lg. 81/08. La scelta della PLE utilizzata si è basata sull'analisi delle caratteristiche della pianta, del tipo di intervento,
                del contesto operativo e del terreno. La macchina verrà piazzata in modo da evitare lo sprofondamento degli stabilizzatori, e in modo da ridurre
                la possibilità di urto con parti della pianta in caduta. L'operatore eseguirà i movimenti in quota in modo di evitare il contatto dei bracci
                meccanici con parti della pianta, manufatti presenti ed ostacoli in genere. Altresì eseguirà i movimenti all'interno della chioma in modo da
                evitare il rischio di schiacciamento o urti con parti della pianta.
              </Text>

              <Text style={textValueTitle}>• DPI utilizzati</Text>
              <Text style={smallTextValue}>
                - Imbrago{'\n\n'}
                - Cordino di trattenuta{'\n\n'}
                - Caschetto{'\n\n'}
                - Guanti{'\n\n'}
                - Occhiali protettivi o visiera{'\n\n'}
              </Text>

              <Text style={textValueTitle}>• Procedura di soccorso in quota</Text>
            </View>}

          {tc && treeClimbing &&
            <View>
              <Text style={textValueTitle}>• Valutazione dell'albero</Text>
              <Text style={smallTextValue}>
                Esaminare l’albero per individuare eventuali criticità:{'\n\n'}
                - Degradazioni del legno evidenziate dalla presenza di corpi fruttiferi di funghi{'\n\n'}
                - Presenza di rami secchi{'\n\n'}
                - Presenza di lesioni o forcelle con corteccia inclusa{'\n\n'}
                - Presenza di insetti pericolosi{'\n\n'}
                Valutare visivamente le condizioni di stabilità dell’albero.{'\n\n'}
                Esaminare la conformazione dell’albero e le possibilità operative
              </Text>

              <Text style={textValueTitle}>• Valutazione dell'area di svolgimento degli interventi</Text>
              <Text style={smallTextValue}>
                Esaminare l’area di svolgimento dei lavori per individuare l’eventuale vicinanza a strade, linee elettriche, edifici o altri manufatti e altre piante.
                Individuare la zona di pericolo, accessibile solo a personale autorizzato e in comunicazione con gli operatori in quota.
                Delimitare l’area in modo adeguato, con cartelli, nastro segnaletico e altri eventuali elementi.
              </Text>

              <Text style={textValueTitle}>• Operazioni di lavoro in quota su fune su alberi</Text>
              <Text style={smallTextValue}>
                Le operazioni verranno eseguite utilizzando la tecnica del tree climbing. Gli operatori impegnati in queste operazioni hanno ricevuto formazione,
                informazione ed addestramento a tale scopo, ai sensi del D.lg. 81/08. La protezione dalle cadute dall’alto deve essere attuata nel rispetto del
                D.lgs. 81/08. I sistemi di protezione utilizzati devono essere conformi alle condizioni esistenti sul luogo di lavoro, come sottolineato nella
                Circolare Inail del 22 Luglio 2016 “Istruzioni per l’esecuzione in sicurezza di lavori su alberi con funi”, in particolare l’uso degli assorbitori
                di energia o anticaduta di tipo guidato su fune, richiedono una condizione ben precisa del luogo di lavoro dove possono essere utilizzati.
                Tali indicazioni sono previste nelle note informative dei costruttori. In alcuni casi sugli alberi non vi è la condizione ottimale per utilizzare
                sistemi che prevedono l’utilizzo di anticaduta di tipo guidato su fune, che è un sistema di arresto caduta; è necessario pertanto accertarsi che
                al di sotto dell’operatore vi sia uno spazio sufficiente e libero da ostacoli. Nei casi eccezionali in cui vi sia assenza di adeguati tiranti d’aria e,
                l’utilizzo di alcuni dpi rappresenti una fonte di rischio per gli operatori (come indicato nell’art. 116 e nell’allegato VIII del D.lgs. 81/08)
                i lavoratori operano mediante sistemi di protezione composti da DPI specifici idonei per il tree climbing, finalizzati a prevenire la caduta conformemente
                a quanto previsto  dall’articolo 115 del d.lgs. 81/08.; tali DPI sono scelti dal Datore di Lavoro e dal Preposto se considerati in questo caso più sicuri
                ed efficienti, in conformità all’art. 76 del d.lgs. 81/08 (Requisiti dei DPI) e all’art. 77 (Obblighi del datore di lavoro) e all’Allegato VII
                (Criteri per la scelta dei DPI).
              </Text>

              <Text style={textValueTitle}>• Rischio sindrome da sospensione</Text>
            </View>}

          {tc && protezioneCadute && (
            <View>
              <Text style={textValueTitle}>• Sistemi di protezione contro le cadute (art. 116)</Text>
              <Text style={textValueTitle}>• Sistemi di accesso e posizionamento mediante funi</Text>
              <Text style={smallTextValue}>
                La conformazione dell’albero oggetto dell’intervento permette un adeguata protezione costante mediante sistemi di arresto caduta.
                Tutti gli interventi in quota saranno svolti in ottemperanza all’art. 116 del d.lgs. 81/08 che descrive l'impiego di sistemi di accesso
                e di posizionamento mediante funi.
              </Text>

              <Text style={textValueTitle}>Descrizione dei sistemi di protezione contro le cadute</Text>
              <Text style={textValueTitle}>Accesso in quota</Text>
              <Text style={smallTextValue}>
                L'accesso in quota avviene mediante l'utilizzo di un sistema di risalita, che resta installato durante tutte le fasi di lavoro, e ha funzione di fune
                di emergenza, rappresentando una rapida via d'accesso per eventuali procedure di soccorso. Nel caso l’albero presenti un’impalcatura dei rami che
                consenta un accesso sicuro all’operatore e ad eventuali soccorritori, è possibile valutare la non istallazione della fune di risalita/emergenza e
                procedere con la salita in progressione. L’istallazione di un sistema d’accesso avviene mediante il lancio del sagolino, a mano o con apposita fionda.
                Prima del lancio viene informato tutto il personale presente. Il sistema di accesso, qualora risulti difficile l’utilizzo di due ancoraggi separati,
                adeguati e vicini tra loro, sarà composto da un solo braccio ascendente di fune a cui sono vincolate la fune di lavoro e di sicurezza utilizzando un
                dispositivo multi ancoraggio, come descritto nella Circolare Inail del 22 Luglio 2016 “Istruzioni per l’esecuzione in sicurezza di lavori su alberi con funi”.
                Laddove si opti per questa soluzione, non è consentito l’utilizzo di qualsiasi utensile o attrezzatura da taglio durante la fase di accesso.
                L’accesso in quota mediante funi, ai sensi del comma 1 dell’articolo 116 del d.lgs. n. 81/2008, deve essere compiuto prevedendo l’impiego di sistemi
                comprendenti almeno due funi ancorate separatamente, una per l’accesso, la discesa e il sostegno, detta fune di lavoro e l’altra con funzione di dispositivo
                ausiliario, detta fune di sicurezza. È ammesso l’uso di una fune in circostanze eccezionali in cui l’uso di una seconda fune rende il lavoro più pericoloso
                e se sono adottate misure adeguate a garantire la sicurezza. Nel caso di salita in progressione viene valutata dall’operatore la possibilità di installare
                un sistema d’accesso dopo la prima risalita, in base alla conformazione dell’albero. In fase di risalita l’operatore continua la valutazione visiva della
                struttura dell’albero e le condizioni di arrampicabilità.
              </Text>

              <Text style={textValueTitle}>Spostamenti nella chioma</Text>
              <Text style={smallTextValue}>
                Durante questa fase il lavoratore deve essere sempre assicurato con due funi ancorate separatamente. In casi eccezionali opportunamente dichiarati, valutati, documentati nel programma dei lavori e confermati dal preposto, è possibile l’uso della sola fune di lavoro, purché sia garantito un equivalente livello di sicurezza.
                Le funi di lavoro vengono installate su sistemi di ancoraggio EN 795 B. La scelta del punto di ancoraggio viene fatta in base alla specie arborea, diametro del fusto e posizione idonea per eseguire il lavoro.
                Gli spostamenti vengono eseguiti sfruttando il sistema di lavoro, la struttura dell’albero e l’applicazione della tecnica della contrapposizione, per mantenere un buon equilibrio.
                Con un ancoraggio adeguato rispetto alla zona di movimentazione è possibile effettuare spostamenti laterali di una certa ampiezza con un rischio limitato di pendolo incontrollato. Nel caso non sussistano queste condizioni vengono utilizzati dispositivi ausiliari come cordini di posizionamento o seconde vie.
              </Text>

              <Text style={textValueTitle}>DPI Utilizzati</Text>
              <Text style={smallTextValue}>
                - Imbragatura di sostegno{'\n\n'}
                - Sistema di accesso e posizionamento mediante funi{'\n\n'}
                - Longe (cordino di posizionamento){'\n\n'}
                - Dispositivi di ancoraggio{'\n\n'}
                - Connettori{'\n\n'}
                - Caschetto{'\n\n'}
                - Guanti{'\n\n'}
                - Occhiali protettivi o visiera
              </Text>

            </View>
          )}

          {tc && protezioneSpecifici && (
            <View>
              <Text style={textValueTitle}>• Sistemi di protezione contro le cadute (specifici)</Text>

              <Text style={textValueTitle}>• DPI e Sistemi di protezione specifici per i lavori sugli alberi</Text>
              <Text style={smallTextValue}>
                La conformazione dell’albero oggetto dell’intervento non permette un adeguata protezione mediante sistemi di arresto caduta per tutta la durata dei lavori.
                Considerata l’assenza di adeguati tiranti d’aria nella maggior parte delle fasi lavorative e essendovi la possibilità di muoversi prevalentemente sulla
                struttura dell’albero si opererà mediante sistemi di protezione composti da DPI specifici per l’attività lavorativa sugli alberi, valutati attentamente
                dal datore di lavoro e considerati in questo caso più sicuri ed efficienti, come indicato nell’art. 76 del d.lgs 81/08 (Requisiti dei DPI) e nell’art. 77
                (Obblighi del datore di lavoro). La riduzione del rischio di caduta sarà attuata con l’uso di idonei sistemi di protezione contro la caduta dall’alto come
                stabilito dall’articolo 115 del d.lgs 81/08.
              </Text>

              <Text style={textValueTitle}>Descrizione dei sistemi di protezione contro le cadute</Text>
              <Text style={textValueTitle}>Accesso in quota</Text>
              <Text style={smallTextValue}>
                L'accesso in quota avviene mediante l'utilizzo di un sistema di risalita, che resta installato durante tutte le fasi di lavoro, e comprendente una fune di
                emergenza. Rappresenta una rapida via d'accesso per eventuali procedure di soccorso. Nel caso l’albero presenti un’impalcatura dei rami che consenta un
                accesso sicuro all’operatore e ad eventuali soccorritori, è possibile valutare la non istallazione della fune di risalita/emergenza e procedere con la salita
                in progressione.
                L’istallazione di un sistema d’accesso avviene mediante il lancio del sagolino, a mano o con apposita fionda. Prima del lancio viene informato tutto il personale
                presente. Il sistema di accesso, qualora risulti difficile l’utilizzo di due ancoraggi separati, adeguati e vicini tra loro, sarà composto da un solo braccio ascendente di
                fune a cui sono vincolate la fune di lavoro e di sicurezza utilizzando un dispositivo multi ancoraggio, come descritto nella Circolare Inail del 22 Luglio 2016
                “Istruzioni per l’esecuzione in sicurezza di lavori su alberi con funi”. Laddove si opti per questa soluzione, non è consentito l’utilizzo di qualsiasi utensile
                o attrezzatura da taglio durante la fase di accesso.
                Valutando il tempo di esposizione al rischio, i tiranti d’aria e le caratteristiche dell’albero, il Preposto, in accordo col Datore di lavoro e i Lavoratori,
                sceglie i DPI specifici più idonei ad essere utilizzati in fase di accesso, in rapporto alla frequenza di circolazione, al dislivello e alla durata dell’impiego,
                per garantire adeguate condizioni di sicurezza e consentire la rapida evacuazione in caso di pericolo imminente (Art. 111 D.lgs 81/08)
                Nel caso di salita in progressione viene valutata dall’operatore la possibilità di installare un sistema d’accesso dopo la prima risalita, in base alla conformazione
                dell’albero.
                In fase di risalita l’operatore continua la valutazione visiva della struttura dell’albero e le condizioni di arrampicabilità.
              </Text>

              <Text style={textValueTitle}>Spostamenti nella chioma</Text>
              <Text style={smallTextValue}>
                Durante questa fase il lavoratore deve essere sempre assicurato con due funi ancorate separatamente. In casi eccezionali opportunamente dichiarati, valutati, documentati nel programma dei lavori e confermati dal preposto, è possibile l’uso della sola fune di lavoro, purché sia garantito un equivalente livello di sicurezza:{'\n\n'}
                a) attraverso la scelta di un meccanismo o dispositivo che non possa essere accidentalmente scollegato dalla fune di lavoro in qualsiasi punto lungo la stessa;{'\n\n'}
                b) garantendo la permanenza, per tutta la durata dei lavori, di una fune di emergenza di lunghezza sufficiente a raggiungere il terreno da qualsiasi punto della chioma della pianta;{'\n\n'}
                c) garantendo che gli spostamenti del lavoratore all’interno della chioma non espongano lo stesso al rischio di oscillazione (effetto pendolo);{'\n\n'}
                d) non utilizzando attrezzature o utensili da taglio;{'\n\n'}
                e) trasportando gli utensili da taglio manuali contenuti in apposita custodia;{'\n\n'}
                f) ecc.{'\n\n'}
                Nei casi eccezionali l’uso della fune di sicurezza può rendere il lavoro più pericoloso in quanto impedisce o limita i movimenti dell’operatore negli spostamenti laterali. (Istruzioni per l’esecuzione in sicurezza di lavori su alberi con funi, 22/07/2016, Inail)
                Sugli alberi interessati dagli interventi vi sono situazioni in cui, sia per l’assenza di adeguati tiranti d’aria o la loro continua variazione in base alla posizione dell’operatore, sia per la densità delle chiome che impediscono un funzionamento corretto dei sistemi di arresto caduta e sia per la necessità di lavorare con inclinazioni delle funi rispetto all’ancoraggio che non permettono uno scorrimento efficiente dei dispositivi 12841A, l’utilizzo della fune di sicurezza renderebbe il lavoro più pericoloso e meno efficace. In accordo col preposto, la movimentazione si svolge con l'utilizzo di una fune di lavoro doppiata o singola, regolata da nodi di frizione certificati e omologati, o dispositivi meccanici certificati e omologati, tutti DPI specifici per il lavoro sugli alberi.
                Le funi di lavoro vengono installate su sistemi di ancoraggio EN 795 B. La scelta del punto di ancoraggio viene fatta in base alla specie arborea, diametro del fusto e posizione idonea per eseguire il lavoro.
                Gli spostamenti vengono eseguiti sfruttando il sistema di lavoro, la struttura dell’albero e l’applicazione della tecnica della contrapposizione, per mantenere un buon equilibrio.
                Con un ancoraggio adeguato rispetto alla zona di movimentazione è possibile effettuare spostamenti laterali di una certa ampiezza con un rischio limitato di pendolo incontrollato. Nel caso non sussistano queste condizioni vengono utilizzati dispositivi ausiliari come cordini di posizionamento o seconde vie.
              </Text>

              <Text style={textValueTitle}>DPI Utilizzati</Text>
              <Text style={smallTextValue}>
                - Imbragatura specifica{'\n\n'}
                - Sistema di accesso{'\n\n'}
                - Sistema di lavoro specifico{'\n\n'}
                - Longe (cordino di posizionamento){'\n\n'}
                - Dispositivi di ancoraggio{'\n\n'}
                - Connettori{'\n\n'}
                - Caschetto{'\n\n'}
                - Guanti{'\n\n'}
                - Occhiali protettivi o visiera
              </Text>
            </View>
          )}

          {tc &&
            <View>
              <Text style={textValueTitle}>• Prescrizioni particolari</Text>
              <Text style={smallTextValue}>
                Per ridurre il rischio di apertura errata di un sistema di protezione, le manovre di passaggio tra un sistema e un altro (es. sistema di lavoro e sistema di posizionamento), vengono effettuate mantenendo sempre in tensione almeno uno dei due sistemi.
                Evitare i pendoli non controllabili e il cui impatto non è ammortizzabile con le gambe.
                In caso di sospensione prolungata cambiare posizione, muovere gli arti inferiori e cercare di appoggiare i piedi sulla struttura dell’albero.
                Cercare posizioni ergonomiche in fase di posizionamento.
                Durante le fasi di taglio dei rami, che avverrà mediante strumenti manuali o a motore, gli operatori si posizioneranno in modo stabile ed utilizzeranno cordini di posizionamento e/o un secondo sistema di lavoro, al fine di ridurre al minimo il rischio di perdita di equilibrio, che potrebbe causare il taglio accidentale della fune di lavoro.
              </Text>

              <Text style={textValueTitle}>• Procedura di soccorso in quota</Text>
            </View>}
        </View>

        <Text style={title}>8. ATTREZZATURE</Text>
        <View style={body}>
          {segacci && <Text style={textValueTitle}>• Segacci manuali</Text>}

          {motoseghe && (
            <View>
              <Text style={textValueTitle}>• Motoseghe</Text>
              <Text style={smallTextValue}>
                Gli operatori che utilizzeranno strumenti di taglio a motore hanno ricevuto formazione, informazione ed addestramento a tale scopo, ai sensi del D.lg. 81/08.
                Qualora si utilizzino strumenti di taglio a motore, gli operatori utilizzeranno i DPI previsti per limitare i rischi di taglio (abbigliamento anti-taglio), i rischi derivanti dal rumore (otoprotettori) ed i rischi derivanti dalla proiezione di schegge (occhiali protettivi o visiera) in base agli art. 76 e 77 del d.lgs. 81/08. Tali DPI verranno utilizzati anche dagli operatori che si troveranno nelle immediate vicinanze.
                Gli operatori non devono assolutamente usare la motosega impugnandola con una sola mano quando hanno una posizione di lavoro non stabile. L’impugnatura con una sola mano deve essere effettuata unicamente da personale specializzato in questo particolare metodo di lavoro ed unicamente destinata alla potatura degli alberi.
              </Text>

              <Text style={textValueTitle}>Procedure di taglio in quota</Text>
              <Text style={smallTextValue}>
                Gli operatori, durante le fasi di taglio, devono sempre mantenere entrambe le mani sulle impugnature; per questo motivo non vengono utilizzati guanti con protezione antitaglio. Inoltre i guanti antitaglio limiterebbero sensibilmente le possibilità di gestire in sicurezza i sistemi di lavoro.
                Prima di intraprendere qualsiasi operazione di taglio è necessario aver concordato con il personale a terra un sistema di comunicazione efficace, il quale può essere verbale, gestuale o a mezzo radio.
                Comunicare sempre in anticipo ogni azione ed attendere una risposta affermativa da parte dei colleghi prima di agire.
                Prima di tagliare posizionarsi in modo stabile ed assicurarsi di essere collegati ad almeno due sistemi di protezione contro le cadute indipendenti.
                Accertarsi che la traiettoria degli organi di taglio non interferisca con la posizione delle funi o di altri dpi.
              </Text>

              <Text style={textValueTitle}>Procedure di taglio a terra (abbattimento al piede)</Text>
              <Text style={smallTextValue}>
                Prima di procedere all'abbattimento valutare i seguenti aspetti:{'\n\n'}
                - Base e tipologia del tronco (presenza di eventuali danneggiamenti, polloni, diametro del tronco, stato di salute, etc.){'\n\n'}
                - Presenza di pericoli particolari (rami o parti di chioma impigliati, impigliamento con altri alberi, presenza di rami secchi){'\n\n'}
                - Forma del fusto e chioma (inclinazioni del fusto, biforcazioni, ripartizione del peso, distribuzione delle biforcazioni){'\n\n'}
                - Dintorni e corridoio di caduta (caratteristiche del luogo di impatto, presenza di vento, possibile trascinamento di altri alberi){'\n\n'}
                Per evitare di essere colpiti da parti della chioma o del fusto che rimbalzano all'indietro, rami che precipitano, o da un rinculo del tronco, è di fondamentale importanza individuare un luogo sicuro e preparare la via di ritirata. Il luogo di ritirata deve essere in direzione opposta a quella di caduta dell'albero (cioè nell'angolo di 90° in direzione opposta alla caduta). La via di ritirata, come la zona immediatamente circostante l’albero da abbattere, deve essere ripulita da cespugli rami secchi e altri ostacoli che possono far inciampare l’operatore. La distanza del luogo di ritirata deve essere stabilita di volta in volta dall’abbattitore in relazione alla orografia del terreno, all’ampiezza della chioma dell’albero da abbattere, alla presenza di rami secchi in chioma.
                Subito prima di procedere al taglio, verificare che nella zona di caduta non ci sia nessuno e avvisare gli altri lavoratori in modo chiaro. Prestare attenzione durante il posizionamento del cuneo di abbattimento, per evitare possibili rinculi. Durante il taglio assumere posizioni stabili, evitando di curvare la schiena. Non appena terminato il taglio, mettersi al sicuro nel luogo di ritirata e da lì osservare la chioma dell'albero e il corridoio di caduta. Prima di avvicinarsi, attendere finché l'albero è a terra e le chiome degli alberi circostanti sono completamente ferme.
              </Text>

              <Text style={textValueTitle}>Procedure di taglio a terra (sramatura e depezzamento)</Text>
              <Text style={smallTextValue}>
                Scegliere la tecnica di sramatura più corretta, scegliere il movimento da compiere intorno all’albero per ridurre il rischio di rotolamento e ottimizzare gli spostamenti.
                Procedere con la sramatura e depezzatura osservando le seguenti regole:{'\n\n'}
                - appoggiare la motosega al corpo o al tronco per ridurre le vibrazioni{'\n\n'}
                - muoversi lungo il tronco a catena ferma{'\n\n'}
                - non tagliare con motosega sopra alle spalle{'\n\n'}
                - non lavorare in due sulla stessa pianta (salvo deroghe in casi ben specificati){'\n\n'}
                - non camminare sul tronco quando questo è più alto di 1,5 m da terra{'\n\n'}
                Nella depezzatura valutare le tensioni nel tronco prima di eseguire i tagli.
                Non mettersi mai a valle del tronco durante il lavoro.
              </Text>

              <Text style={textValueTitle}>DPI Utilizzati</Text>
              <Text style={smallTextValue}>
                - Abbigliamento anti-taglio{'\n\n'}
                - Casco{'\n\n'}
                - Guanti{'\n\n'}
                - Occhiali protettivi o visiera{'\n\n'}
              </Text>
            </View>
          )}

          {abbattimento && (
            <View>
              <Text style={textValueTitle}>• Sistemi di abbattimento controllato</Text>
              <Text style={smallTextValue}>
                Nell’utilizzo di sistemi di abbattimento controllato va prestata particolare attenzione ad evitare interferenze tra i sistemi di accesso e posizionamento e le attrezzature per la calata. È fondamentale il controllo reciproco tra i componeneti della squadra di lavoro.
                Nelle operazioni di calata e sollevamento il frizionista deve:{'\n\n'}
                - Utilizzare i DPI necessari (guanti in pelle, casco, scarpe da lavoro){'\n\n'}
                - Evitare di attorcigliare la fune di servizio alle mani e mantenerla ordinata negli appositi contenitori{'\n\n'}
                - Non abbandonare l’area sicura per il frizionista{'\n\n'}
                - Durante le manovre in quota rispettare le aree di sicurezza.{'\n\n'}
                Ogni operazione deve essere eseguita secondo il manuale d’uso dell’attrezzatura.
              </Text>

              <Text style={textValueTitle}>DPI Utilizzati</Text>
              <Text style={smallTextValue}>
                - Guanti in pelle{'\n\n'}
                - Casco{'\n\n'}
                - Occhiali protettivi o visiera{'\n\n'}
                - Scarponi{'\n\n'}
              </Text>
            </View>
          )}

          {(cippatrice || fresaceppi) && (
            <View>
              <Text style={textValueTitle}>• Operazioni di cippatura e/o fresatura ceppaie e/o air speed</Text>
              <Text style={smallTextValue}>
                Queste operazioni verranno eseguite utilizzando attrezzature con sistemi di sicurezza idonei ed indossando i DPI previsti dal produttore.
              </Text>

              <Text style={textValueTitle}>DPI Utilizzati</Text>
              <Text style={smallTextValue}>
                - Casco{'\n\n'}
                - Guanti{'\n\n'}
                - Occhiali protettivi o visiera{'\n\n'}
                - Otoprotettori{'\n\n'}
              </Text>
            </View>
          )}

          {attrezzatureAltre && <Text style={textValueTitle}>• Altre attrezzature</Text>}
          {attrezzatureAltre && <Text style={textValueTitle}>• Note Altre attrezzature</Text>}
          {attrezzatureAltre && <Text style={{ ...textValueTitle, marginLeft: 20, fontStyle: 'italic' }}>{noteAltreAttrezzature}</Text>}
        </View>

        <Text style={title}>9. PIANO DI EMERGENZA</Text>
        <View style={body}>
          <Text style={{ ...title, marginLeft: 0, textDecoration: 'underline', marginBottom: 15 }}>Numeri utili:</Text>
          <Text style={textValue}>Numero unico emergenza: 112</Text>
          <Text style={textValue}>Collocazione Cassetta primo soccorso in cantiere: ____________________________________________</Text>
          <Text style={textValue}>Ospedale più vicino (facoltativo): ____________________________________________</Text>
          <Text style={textValue}>Area atterraggio elicottero più vicina (facoltativo): ____________________________________________</Text>

          <Text style={{ ...title, marginLeft: 0, textDecoration: 'underline', marginBottom: 15 }}>Procedura di emergenza:</Text>
          <Text style={textValue}>1) Avvisare gli altri componenti della squadra</Text>
          <Text style={textValue}>2) Valutare condizioni del ferito</Text>
          <Text style={textValue}>3) Valutare le condizioni del sito di lavoro</Text>
          <Text style={textValue}>4) Chiamare 112</Text>
          <Text style={textValue}>Indicare il punto in cui si attendono i soccorsi e il numero di cellulare</Text>
          <Text style={textValue}>a cui poter richiamare in caso di necessità</Text>
          <View style={hor}>
            <Text style={{ ...subtitle, textDecoration: 'underline', marginBottom: 10 }}>Indirizzo: </Text>
            <Text style={textValue}>{indirizzo}</Text>
          </View>
          <Text style={textValue}>5) Valutare se vi è pericolo per i soccoritori</Text>
          <Text style={textValue}>6) Fare in modo che il ferito sia facilmente raggiungibile dal personale di</Text>
          <Text style={textValue}>soccorso; Se necessario, avviare le procedure di recupero in quota</Text>
          <Text style={textValue}>7) Confortare il ferito se cosciente</Text>
          <Text style={textValue}>8) Se non cosciente valutare se inziare la procedura di rianimazione - ABC</Text>
          <Text style={textValue}>Mantenere il contatto telefonico con il personale del 112</Text>

          <Text style={textValueTitle}>Procedura di soccorso in quota</Text>
          <Text style={smallTextValue}>
            Il personale presente è formato per eventuali procedure di soccorso in quota, nonché ad apportare i primi soccorsi di base
          </Text>

          <Text style={textValueTitle}>Tree climbing</Text>
          <Text style={smallTextValue}>
            Sull'albero è presente, durante lo svolgimento delle operazioni, una fune di emergenza in modo da garantire una rapida via d'accesso in quota. Inoltre il sistema è svincolabile dalla base dell’albero.
            Nel caso l’albero presenti un’impalcatura dei rami che consenta un accesso sicuro all’operatore e ad eventuali soccorritori o in caso di utilizzo dei ramponi, è possibile procedere con la salita in progressione in caso di emergenza.
            Nell’area di lavoro è sempre presente un paio di ramponi in più.
            Raggiunto il ferito, il soccorritore, adeguatamente formato, valuterà la più idonea procedura di soccorso in base al tipo di infortunio, alla posizione del ferito ed al contesto generale.
          </Text>

          <Text style={textValueTitle}>PLE</Text>
          <Text style={smallTextValue}>
            Nel caso gli operatori coinvolti dall’evento non siano in grado di pilotare il braccio fino a terra, il soccorritore dovrà utilizzare i comandi a terra. Se non previsti dalla macchina o malfunzionanti, dovrà azionare i comandi idraulico-manuali, seguendo le istruzioni fornite dal libretto d’uso della macchina a disposizione nel vano porta documenti dell’abitacolo.
          </Text>
        </View>

        <Text style={title}>10. NOTE</Text>
        <View style={body}>
          <Text style={{ ...textValue, marginLeft: 10, fontStyle: 'italic' }}>{note}</Text>
        </View>

        <Text style={title}>11. FIRMA PER PRESA VISIONE E PIENA COMPRENSIONE</Text>
        <View style={body}>
          {operatori && operatori.length > 0 && operatori.map((user, index) => {
            return (
              <View style={hor} key={index}>
                <Text style={{ ...textValue, margin: 10 }}>{user.nome}</Text>
                <Text style={textValue}>____________________________________________</Text>
              </View>
            )
          })}
        </View>
        <Text style={title}>12. DATA</Text>
        <View style={body}>
          <Text style={textValue}>{moment(new Date()).format('DD/MM/YYYY')}</Text>
        </View>
        {/* <Text style={pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed /> */}
      </Page>
    </Document>
  )
}

export default MyDocument

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    paddingTop: 40,
    paddingBottom: 40,
    fontSize: 14,
    color: Config.textColor
  },
  pageHeader: {
    position: 'absolute',
    top: 15,
    fontSize: 12,
    textAlign: 'center',
    color: Config.textColor
  },
  pageNumber: {
    position: 'absolute',
    bottom: 15,
    fontSize: 12,
    textAlign: 'center',
    color: Config.textColor
  },
  header: {
    fontSize: 14,
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    fontStyle: 'italic',
    fontWeight: 'bold',
    color: Config.textColor,
    textTransform: 'uppercase'
  },
  title: {
    fontSize: 12,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 50,
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: Config.textColor
  },
  body: {
    marginLeft: 60,
    color: Config.textColor
  },
  hor: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5
  },
  subtitle: {
    color: Config.textColor,
    fontSize: 10,
    textTransform: 'uppercase',
    marginRight: 10
  },
  textValueTitle: {
    color: Config.textColor,
    fontSize: 12,
    textTransform: 'uppercase',
    marginRight: 10,
    marginTop: 15,
    marginBottom: 8
  },
  textValue: {
    color: 'black',
    fontSize: 14
  },
  smallTextValue: {
    color: 'black',
    fontSize: 10,
    marginRight: 50
  }
})
