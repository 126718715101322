import React, { Component } from 'reactn'
import { Card, InputGroup, FormControl, FormCheck, Button } from 'react-bootstrap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import DatePicker from 'react-date-picker'
import * as FaIcons from 'react-icons/fa'
import swal from 'sweetalert'

import 'moment/locale/it'
import MyDocument from '../Doc/Document'
import Navbar from '../Components/Navbar'
import Config from '../Global/Config'
import { Api, persistState } from '../Services'

const DEFAULT_STATE = {
  downloadPdf: false,

  companyName: '',
  companyAddress: '',
  vat: '',
  personInCharge: '',
  firstAid: '',
  firePrevention: '',

  dataSopralluogo: new Date(),
  committente: '',
  indirizzo: '',
  indicazioni: '',
  dataEsecuzione: new Date(),
  tipologiaIntervento: '...',
  numero: '',
  specie: '',
  dimensioni: '',

  comunicazione: false,
  rischioCaduta: false,
  rischioTaglio: false,
  rischioAmbiente: false,
  interferenze: false,
  pedoni: false,
  condotte: false,
  rischioUrti: false,
  rischioClima: false,
  rischioBiologico: false,
  rischioChimico: false,
  rischioSchegge: false,
  rischioSchiacciamento: false,
  rischioAltri: false,
  noteAltriRischi: '',

  ple: false,
  tc: false,
  treeClimbing: false,
  protezioneCadute: false,
  protezioneSpecifici: false,
  segacci: false,
  motoseghe: false,
  abbattimento: false,
  cippatrice: false,
  fresaceppi: false,
  attrezzatureAltre: false,
  noteAltreAttrezzature: '',

  note: '',
  operatori: []
}

export default class New extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...DEFAULT_STATE
    }
  }

  async componentDidMount () {
    const { row } = this.global
    await this.startup()
    this.setState({ ...row, modHistory: !!row })
    await this.setGlobal({ row: null })
  }

  async startup () {
    const { user, row } = this.global
    const companyId = user.company
    const res = await Api.get(`/companies/${companyId}`)

    if (res.ok) {
      const company = res.data
      const { name, address, vat, personInCharge, firstAid, firePrevention, operatori } = company

      let added = {}
      let preposto
      let primoSoccorso
      let prevenzioneIncendi

      if (!row) {
        added = { ...DEFAULT_STATE }

        // directly from company data
        preposto = personInCharge.length === 1 ? personInCharge[0] : ''
        primoSoccorso = firstAid.length === 1 ? firstAid[0] : ''
        prevenzioneIncendi = firePrevention.length === 1 ? firePrevention[0] : ''
      } else {
        // from history data
        preposto = row.preposto
        primoSoccorso = row.primoSoccorso
        prevenzioneIncendi = row.prevenzioneIncendi
      }

      this.setState({
        ...added,
        companyName: name,
        companyAddress: address,
        vat,
        personInCharge,
        firstAid,
        firePrevention,
        preposto,
        primoSoccorso,
        prevenzioneIncendi,
        staticOperators: operatori
      })
    } else {
      swal('Errore', 'Impossibile inizializzare la pagina. Contattare l\'assistenza', 'error')
    }
  }

  addOperatoreFree () {
    const { operatori } = this.state
    const array = operatori
    const obj = {
      nome: '',
      luogo: '',
      tipo: '',
      static: false
    }
    array.push(obj)
    this.setState({ operatori: array })
  }

  addOperatoreStatic () {
    const { operatori } = this.state
    const array = operatori
    const obj = {
      nome: '',
      luogo: '',
      tipo: '',
      static: true
    }
    array.push(obj)
    this.setState({ operatori: array })
  }

  removeOperatore () {
    const { operatori } = this.state
    const array = operatori
    array.length > 0 && array.pop()
    this.setState({ operatori: array })
  }

  handleChange (e, index, type) {
    const { operatori } = this.state
    operatori[index][type] = e.target.value
    this.forceUpdate()
  }

  checkPdfData () {
    this.setState({ downloadPdf: true })
    // const {
    //   committente, indirizzo, indicazioni, dataEsecuzione, tipologiaIntervento,
    //   numero, specie, dimensioni, operatori
    // } = this.state

    // let checkOperatori = operatori.length > 0
    // operatori.forEach(item => {
    //   if (item.luogo === '' || item.nome === '' || item.tipo === '') {
    //     checkOperatori = false
    //   }
    // })

    // if (
    //   committente === '' || indirizzo === '' || indicazioni === '' ||
    //   !dataEsecuzione || tipologiaIntervento === '...' || numero === 0 ||
    //   specie === 0 || dimensioni === '' || !checkOperatori
    // ) {
    //   swal('Attenzione', 'Alcuni campi non sono stati completati', 'warning')
    // } else {
    //   this.setState({ downloadPdf: true })
    // }
  }

  async postGeneration () {
    const { user } = this.global
    const companyId = user.company
    const userId = user._id.toString()

    const {
      _id, modHistory,
      preposto, primoSoccorso, prevenzioneIncendi,
      dataSopralluogo, committente, indirizzo, indicazioni, dataEsecuzione, tipologiaIntervento,
      numero, specie, dimensioni, comunicazione, rischioCaduta, rischioTaglio,
      rischioAmbiente, interferenze, pedoni, condotte, rischioUrti, rischioClima,
      rischioBiologico, rischioChimico, rischioSchegge, rischioSchiacciamento,
      rischioAltri, noteAltriRischi, ple, tc, treeClimbing, protezioneCadute, protezioneSpecifici,
      segacci, motoseghe, abbattimento, cippatrice, fresaceppi, attrezzatureAltre, noteAltreAttrezzature,
      note, operatori
    } = this.state

    const data = {
      companyId,
      userId,
      preposto,
      primoSoccorso,
      prevenzioneIncendi,
      dataSopralluogo,
      committente,
      indirizzo,
      indicazioni,
      dataEsecuzione,
      tipologiaIntervento,
      numero,
      specie,
      dimensioni,
      comunicazione,
      rischioCaduta,
      rischioTaglio,
      rischioAmbiente,
      interferenze,
      pedoni,
      condotte,
      rischioUrti,
      rischioClima,
      rischioBiologico,
      rischioChimico,
      rischioSchegge,
      rischioSchiacciamento,
      rischioAltri,
      noteAltriRischi,
      ple,
      tc,
      treeClimbing,
      protezioneCadute,
      protezioneSpecifici,
      segacci,
      motoseghe,
      abbattimento,
      cippatrice,
      fresaceppi,
      attrezzatureAltre,
      noteAltreAttrezzature,
      note,
      operatori,
      createdAt: new Date()
    }

    let res
    if (modHistory) {
      res = await Api.patch(`/lavori/${_id}`, data)
      await this.setGlobal({ row: data }, persistState)
    } else {
      res = await Api.post('/lavori', data)
    }

    if (res.ok) {
      swal('Successo', `Il documento é stato ${modHistory ? 'aggiornato' : 'salvato'} correttamente`, 'success').then(() => this.startup())
    } else {
      swal('Errore', 'Errore durante il salvataggio dei dati. Si prega di riprovare', 'error')
    }

    this.setState({ downloadPdf: false })
  }

  async clearDocument () {
    await this.setGlobal({ row: null }, persistState)
    window.location.reload()
  }

  render () {
    const {
      companyName, companyAddress, vat, personInCharge, preposto, firstAid, primoSoccorso, firePrevention, prevenzioneIncendi,
      dataSopralluogo, committente, indirizzo, indicazioni, dataEsecuzione, tipologiaIntervento,
      numero, specie, dimensioni, comunicazione, rischioCaduta, rischioTaglio,
      rischioAmbiente, interferenze, pedoni, condotte, rischioUrti, rischioClima,
      rischioBiologico, rischioChimico, rischioSchegge, rischioSchiacciamento,
      rischioAltri, noteAltriRischi, ple, tc, treeClimbing, protezioneCadute, protezioneSpecifici,
      segacci, motoseghe, abbattimento, cippatrice, fresaceppi, attrezzatureAltre, noteAltreAttrezzature,
      note, operatori, staticOperators, downloadPdf, modHistory
    } = this.state

    const { baseCard, title, titleBox } = styles

    return (
      <>
        <Navbar title='Nuovo Documento' subtitle='Programma dei lavori (D.Lgs. 81/08, art. 116, comma (e) ed (f))' />
        <div className='new'>
          {
            modHistory &&
              <Button
                size='md'
                variant='warning'
                style={{ marginBottom: 20, position: 'fixed', right: 10, top: 80, zIndex: 999, display: !downloadPdf ? 'block' : 'none' }}
              >
                Documento Storico
              </Button>
          }
          {
            modHistory &&
              <Button
                size='md'
                variant='primary'
                onClick={() => this.clearDocument()}
                style={{ marginBottom: 20, position: 'fixed', right: 10, top: 140, zIndex: 999, display: 'block' }}
              >
                Pulisci campi
              </Button>
          }

          <Button
            size='md'
            variant='danger'
            style={{ marginBottom: 20, position: 'fixed', right: 10, bottom: 0, zIndex: 999, display: downloadPdf ? 'block' : 'none' }}
            onClick={() => this.postGeneration()}
          >
            {
              downloadPdf && (
                <PDFDownloadLink
                  style={{ color: 'white' }}
                  document={<MyDocument data={this.state} />}
                  fileName='Documento'
                >
                  Salva Documento
                  <FaIcons.FaFilePdf style={{ marginLeft: 10, marginBottom: 5 }} size={25} />
                </PDFDownloadLink>
              )
            }
          </Button>

          <Button
            size='md'
            variant='success'
            style={{ marginBottom: 20, position: 'fixed', right: 10, bottom: 0, zIndex: 999, display: !downloadPdf ? 'block' : 'none' }}
            onClick={() => this.checkPdfData()}
          >
            Genera PDF
            <FaIcons.FaFilePdf style={{ marginLeft: 10, marginBottom: 5 }} size={25} />
          </Button>

          <div style={{ maxWidth: '70%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            {/* 1. ANAGRAFICA DITTA */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>1. ANAGRAFICA AZIENDA</b>
                <hr />
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Ragione Sociale</InputGroup.Text>
                  <FormControl value={companyName} disabled />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Indirizzo</InputGroup.Text>
                  <FormControl value={companyAddress} disabled />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Partita IVA</InputGroup.Text>
                  <FormControl value={vat} disabled />
                </InputGroup>

                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Preposto</InputGroup.Text>
                  {
                    personInCharge && personInCharge.length === 1 && <FormControl value={personInCharge} disabled />
                  }
                  {
                    personInCharge && personInCharge.length > 1 && (
                      <FormControl value={preposto} as='select' onChange={(e) => this.setState({ preposto: e.target.value })}>
                        <option value='...'>...</option>
                        {
                          personInCharge.map((item, i) => {
                            return <option key={i} value={item}>{item}</option>
                          })
                        }
                      </FormControl>
                    )
                  }
                </InputGroup>

                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Addetto primo soccorso</InputGroup.Text>
                  {
                    firstAid && firstAid.length === 1 && <FormControl value={firstAid} disabled />
                  }
                  {
                    firstAid && firstAid.length > 1 && (
                      <FormControl value={primoSoccorso} as='select' onChange={(e) => this.setState({ primoSoccorso: e.target.value })}>
                        <option value='...'>...</option>
                        {
                          firstAid.map((item, i) => {
                            return <option key={i} value={item}>{item}</option>
                          })
                        }
                      </FormControl>
                    )
                  }
                </InputGroup>

                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Addetto antincendio</InputGroup.Text>
                  {
                    firePrevention && firePrevention.length === 1 && <FormControl value={firePrevention} disabled />
                  }
                  {
                    firePrevention && firePrevention.length > 1 && (
                      <FormControl value={prevenzioneIncendi} as='select' onChange={(e) => this.setState({ prevenzioneIncendi: e.target.value })}>
                        <option value='...'>...</option>
                        {
                          firePrevention.map((item, i) => {
                            return <option key={i} value={item}>{item}</option>
                          })
                        }
                      </FormControl>
                    )
                  }
                </InputGroup>
              </Card.Body>
            </Card>

            {/* 2. DATI LUOGO DI LAVORO */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>2. DATI LUOGO DI LAVORO</b>
                <hr />
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Data sopralluogo</InputGroup.Text>
                  <DatePicker onChange={date => this.setState({ dataSopralluogo: date })} value={dataSopralluogo} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Committente</InputGroup.Text>
                  <FormControl value={committente} onChange={(e) => this.setState({ committente: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Indirizzo</InputGroup.Text>
                  <FormControl value={indirizzo} onChange={(e) => this.setState({ indirizzo: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Indicazioni per l'accesso</InputGroup.Text>
                  <FormControl value={indicazioni} placeholder='pedonale, strada, scale' onChange={(e) => this.setState({ indicazioni: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Data esecuzione</InputGroup.Text>
                  <DatePicker onChange={date => this.setState({ dataEsecuzione: date })} value={dataEsecuzione} />
                </InputGroup>
              </Card.Body>
            </Card>

            {/* 3. DATI INTERVENTI DA ESEGUIRE */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>3. DATI INTERVENTI DA ESEGUIRE</b>
                <hr />
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Tipologia intervento</InputGroup.Text>
                  <FormControl value={tipologiaIntervento} as='select' onChange={(e) => this.setState({ tipologiaIntervento: e.target.value })}>
                    <option value='...'>...</option>
                    <option value='Potatura'>Potatura</option>
                    <option value='Abbattimento'>Abbattimento</option>
                    <option value='Consolidamento'>Consolidamento</option>
                    <option value='Analisi in quota'>Analisi in quota</option>
                    <option value='Altro'>Altro</option>
                  </FormControl>
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Numero</InputGroup.Text>
                  <FormControl type='number' value={numero} onChange={(e) => this.setState({ numero: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Specie</InputGroup.Text>
                  <FormControl value={specie} onChange={(e) => this.setState({ specie: e.target.value })} />
                </InputGroup>
                <InputGroup className='mb-2'>
                  <InputGroup.Text style={titleBox}>Altezza, diametro</InputGroup.Text>
                  <FormControl placeholder='10m, 40cm' value={dimensioni} onChange={(e) => this.setState({ dimensioni: e.target.value })} />
                </InputGroup>
              </Card.Body>
            </Card>

            {/* 4. SQUADRA DI LAVORO */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>4. SQUADRA DI LAVORO</b>
                <hr />
                <FaIcons.FaUserPlus style={{ position: 'absolute', top: 15, right: 150, fontSize: '2rem', color: 'green' }} onClick={() => this.addOperatoreStatic()} />
                <FaIcons.FaUserPlus style={{ position: 'absolute', top: 15, right: 90, fontSize: '2rem', color: 'orange' }} onClick={() => this.addOperatoreFree()} />
                <FaIcons.FaUserMinus style={{ position: 'absolute', top: 15, right: 30, fontSize: '2rem', color: 'red' }} onClick={() => this.removeOperatore()} />
                {operatori && operatori.length > 0 && operatori.map((user, index) => {
                  return (
                    <InputGroup key={index} className='mb-2'>
                      <InputGroup.Text style={titleBox}>{index + 1}.</InputGroup.Text>
                      {
                        !user.static && (
                          <FormControl value={user.nome} placeholder='' onChange={(e) => this.handleChange(e, index, 'nome')} />
                        )
                      }
                      {
                        user.static && (
                          <FormControl as='select' onChange={(e) => this.handleChange(e, index, 'nome')}>
                            <option value='...'>...</option>
                            {
                              staticOperators && staticOperators.length > 0 && staticOperators.map((op, i) => {
                                return <option key={i} value={op}>{op}</option>
                              })
                            }
                          </FormControl>
                        )
                      }
                      <FormControl value={user.luogo} as='select' onChange={(e) => this.handleChange(e, index, 'luogo')}>
                        <option value='...'>...</option>
                        <option value='In quota'>In quota</option>
                        <option value='A terra'>A terra</option>
                      </FormControl>
                      <FormControl value={user.tipo} as='select' onChange={(e) => this.handleChange(e, index, 'tipo')}>
                        <option value='...'>...</option>
                        <option value='Datore di lavoro'>Datore di lavoro</option>
                        <option value='Dipendente'>Dipendente</option>
                        <option value='Partita IVA'>Partita IVA</option>
                        <option value='Altro'>Altro</option>
                      </FormControl>
                    </InputGroup>
                  )
                })}
                {operatori && operatori.length === 0 && <i>Nessun operatore aggiunto</i>}
              </Card.Body>
            </Card>

            {/* 5. VALUTAZIONE DEI RISCHI */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>5. VALUTAZIONE DEI RISCHI</b>
                <hr />
                <FormCheck checked={comunicazione} label='Comunicazione' onChange={(e) => this.setState({ comunicazione: e.target.checked })} />
                <FormCheck checked={rischioCaduta} label="Rischio caduta dall'alto" onChange={(e) => this.setState({ rischioCaduta: e.target.checked })} />
                <FormCheck checked={rischioTaglio} label='Rischio di taglio' onChange={(e) => this.setState({ rischioTaglio: e.target.checked })} />
                <FormCheck checked={rischioAmbiente} label="Rischi derivanti dall'ambiente circostante" onChange={(e) => this.setState({ rischioAmbiente: e.target.checked })} />
                <FormCheck checked={interferenze} label='Presenza e interferenza con traffico veicolare' onChange={(e) => this.setState({ interferenze: e.target.checked })} />
                <FormCheck checked={pedoni} label='Possibile passaggio di pedoni' onChange={(e) => this.setState({ pedoni: e.target.checked })} />
                <FormCheck checked={condotte} label='Presenza di condotte di energia elettrica' onChange={(e) => this.setState({ condotte: e.target.checked })} />
                <FormCheck checked={rischioUrti} label="Rischio di urto con parti dell'albero o attrezzature in caduta" onChange={(e) => this.setState({ rischioUrti: e.target.checked })} />
                <FormCheck checked={rischioClima} label='Rischi derivanti da situazini climatiche' onChange={(e) => this.setState({ rischioClima: e.target.checked })} />
                <FormCheck checked={rischioBiologico} label='Rischio biologico' onChange={(e) => this.setState({ rischioBiologico: e.target.checked })} />
                <FormCheck checked={rischioChimico} label='Rischio chimico' onChange={(e) => this.setState({ rischioChimico: e.target.checked })} />
                <FormCheck checked={rischioSchegge} label='Rischio di proiezione di schegge' onChange={(e) => this.setState({ rischioSchegge: e.target.checked })} />
                <FormCheck checked={rischioSchiacciamento} label='Rischio di schiacciamento' onChange={(e) => this.setState({ rischioSchiacciamento: e.target.checked })} />
                <FormCheck checked={rischioAltri} label='Altri rischi' onChange={(e) => this.setState({ rischioAltri: e.target.checked })} />
                {
                  rischioAltri && (
                    <InputGroup className='mb-2'>
                      <FormControl
                        rows={6}
                        as='textarea'
                        placeholder='Specificare altri rischi...'
                        value={noteAltriRischi}
                        style={{ marginTop: 10 }}
                        onChange={(e) => this.setState({ noteAltriRischi: e.target.value })}
                      />
                    </InputGroup>
                  )
                }
              </Card.Body>
            </Card>

            {/* 6. TIPOLOGIA OPERATIVA (PLE, TC) // 7. TECNICHE E PROCEDURE OPERATIVE */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>6. TIPOLOGIA OPERATIVA (PLE, TC)</b>
                <hr />
                <FormCheck checked={ple} label='PLE' onChange={(e) => this.setState({ ple: e.target.checked })} />
                <FormCheck checked={tc} label='TC' onChange={(e) => this.setState({ tc: e.target.checked })} />
                {tc &&
                  <div style={{ margin: 10 }}>
                    <hr />
                    <b style={title}>7. TECNICHE E PROCEDURE OPERATIVE</b>
                    <br />
                    <br />
                    <InputGroup className='mb-2'>
                      <InputGroup.Text>L'albero è sicuro per poter operare in tree climbing?</InputGroup.Text>
                      <FormCheck checked={treeClimbing} style={{ marginTop: 5, marginLeft: 10 }} label='SI' onChange={() => this.setState({ treeClimbing: true })} />
                      <FormCheck checked={!treeClimbing} style={{ marginTop: 5, marginLeft: 10 }} label='NO' onChange={() => this.setState({ treeClimbing: false })} />
                    </InputGroup>
                    <FormCheck checked={protezioneCadute} label='Sistemi di protezione contro le cadute (art. 116)' onChange={(e) => this.setState({ protezioneCadute: e.target.checked })} />
                    <FormCheck checked={protezioneSpecifici} label='Sistemi di protezione contro le cadute (specifici)' onChange={(e) => this.setState({ protezioneSpecifici: e.target.checked })} />
                  </div>}
              </Card.Body>
            </Card>

            {/* 8. ATTREZZATURE */}
            <Card style={baseCard} bg='#fff'>
              <Card.Body>
                <b style={title}>8. ATTREZZATURE</b>
                <hr />
                <FormCheck checked={segacci} label='Segacci manuali' onChange={(e) => this.setState({ segacci: e.target.checked })} />
                <FormCheck checked={motoseghe} label='Motoseghe' onChange={(e) => this.setState({ motoseghe: e.target.checked })} />
                <FormCheck checked={abbattimento} label='Sistemi di abbattimento controllato' onChange={(e) => this.setState({ abbattimento: e.target.checked })} />
                <FormCheck checked={cippatrice} label='Cippatrice' onChange={(e) => this.setState({ cippatrice: e.target.checked })} />
                <FormCheck checked={fresaceppi} label='Fresaceppi' onChange={(e) => this.setState({ fresaceppi: e.target.checked })} />
                <FormCheck checked={attrezzatureAltre} label='Altre attrezzature' onChange={(e) => this.setState({ attrezzatureAltre: e.target.checked })} />
                {
                  attrezzatureAltre && (
                    <InputGroup className='mb-2'>
                      <FormControl
                        rows={6}
                        as='textarea'
                        placeholder='Specificare altre attrezzature...'
                        value={noteAltreAttrezzature}
                        style={{ marginTop: 10 }}
                        onChange={(e) => this.setState({ noteAltreAttrezzature: e.target.value })}
                      />
                    </InputGroup>
                  )
                }
              </Card.Body>
            </Card>

            {/* 10. NOTE */}
            <Card style={{ ...baseCard, marginBottom: 100 }} bg='#fff'>
              <Card.Body>
                <b style={title}>10. NOTE</b>
                <hr />
                <InputGroup className='mb-2'>
                  {/* <InputGroup.Text style={{ marginTop: 10 }}>NOTE:</InputGroup.Text> */}
                  <FormControl value={note} style={{ marginTop: 10 }} as='textarea' rows={6} onChange={(e) => this.setState({ note: e.target.value })} />
                </InputGroup>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    )
  }
}

const styles = {
  baseCard: { width: '50%', minWidth: 360, margin: 10, ...Config.shadow, borderRadius: 5 },
  title: {
    fontSize: 14,
    color: Config.textColor
  },
  titleBox: {
    fontSize: 12,
    color: Config.textColor,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
}
